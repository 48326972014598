import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';

const App = () => {

  let content = <Dashboard />;

  return content;

};

export default App;